import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import {
  ArrowTopRightOnSquareIcon,
  MapPinIcon,
  PhoneIcon
} from '@heroicons/react/24/outline';

import {
  Box,
  Cluster,
  Stack
} from '../EveryLayout';
import {
  LogoInline,
  SocialPages
} from '../Page';

const Footer = ({ location }) => (
  <>
    {/* This one requires some special formattting. */}
    <div className="xl:flex xl:content-start xl:items-stretch">
      <div className="hidden xl:block xl:flex-1" />
      <div className="xl:grow max-w-8xl mx-auto">
        {/* Actual footer begins here */}
        <Stack>
          <Cluster className="justify-between items-stretch lg:items-end xl:items-center">
            <div className="sm:flex-1">
              <Cluster className="xl:justify-between">
                <div>
                  <Box className="prose text-gray-50 2xl:text-lg">
                    <span className="font-semibold">Internationella Engelska Gymnasiet Södermalm (IEGS)</span> is an international Upper Secondary school located in the heart of Södermalm with a strong academic tradition and a commitment to helping students develop to their full potential.
                  </Box>
                </div>
                <div>
                  <Box className="xl:-ml-4">
                    <span className="text-gray-50">Follow us!</span>
                    <SocialPages />
                  </Box>
                  <Box className="xl:-ml-4">
                    <a
                      href="https://sms.schoolsoft.se/engelska/jsp/Login.jsp"
                      rel="nofollow noreferrer"
                      className="flex items-center underline-offset-3 decoration-1 decoration-gray-400 text-gray-200 text-sm hover:text-white hover:decoration-white lg:text-base"
                    >
                      SchoolSoft <span className="inline-block ml-2 -mr-1 w-5 h-5"><ArrowTopRightOnSquareIcon className="stroke-2 h-5 w-5" aria-hidden="true" /></span>
                    </a>
                  </Box>
                </div>
                <div>
                  <Box>
                    <nav>
                      <Cluster as="ul">
                        <li className="mb-1">
                          <Link to="/cookie-policy/" className="underline-offset-3 decoration-1 decoration-gray-400 text-gray-200 hover:text-white hover:decoration-white">Cookie Policy</Link>
                        </li>
                        <li className="mb-1">
                          <a href="https://sites.google.com/engelska.se/ies-privacy-policy-notice" rel="nofollow noreferrer" className="underline-offset-3 decoration-1 decoration-gray-400 text-gray-200 hover:text-white hover:decoration-white">Privacy Notice</a>
                        </li>
                      </Cluster>
                    </nav>
                  </Box>
                </div>
              </Cluster>
            </div>
            <div className="w-full sm:w-2/5 xl:w-1/4 bg-navy-blue-700 xl:bg-navy-blue-500 xl:border-l xl:border-white-trans">
              <Box>
                <Box className="max-w-[40%] sm:max-w-[50%]">
                  <div
                    className="text-white"
                  >
                    <LogoInline alt="Internationella Engelska Gymnasiet logo" className="h-32 sm:h-24 w-auto lg:h-24 xl:h-28 2xl:h-28" />
                  </div>
                  {/*
                  <div className="aspect-w-1 aspect-h-1">
                    <div className="border border-gray-300 w-full object-center object-cover">LOGO</div>
                  </div>
                  */}
                </Box>
                <Box as="address" className="prose not-italic text-gray-100 2xl:whitespace-nowrap">
                  <span className="font-bold">Internationella Engelska Gymnasiet</span>
                  <br />
                  Allhelgonagatan 4
                  <br />
                  11858 Stockholm
                  <br />
                </Box>
                <Box>
                  <Stack>
                    <a
                      href="https://goo.gl/maps/TyGTitzjwbcMtpwy6"
                      rel="nofollow noreferrer"
                      className="flex items-center underline-offset-3 decoration-1 decoration-gray-400 text-gray-200 text-sm hover:text-white hover:decoration-white lg:text-base"
                    >
                      <span className="inline-block mr-2 -ml-1 w-5 h-5"><MapPinIcon className="stroke-2 h-5 w-5" aria-hidden="true" /></span> View Map
                    </a>
                    <Link to="/contact/" className="flex items-center underline-offset-3 decoration-1 decoration-gray-400 text-gray-200 text-sm hover:text-white hover:decoration-white lg:text-base">
                      <span className="inline-block mr-2 -ml-1 w-5 h-5"><PhoneIcon className="stroke-2 h-5 w-5" aria-hidden="true" /></span> Contact Us
                    </Link>
                  </Stack>
                </Box>
              </Box>
            </div>
          </Cluster>
          <Cluster className="mt-0 items-stretch">
            <div className="sm:flex-1">
              <Box className="pb-16 text-center sm:text-left">
                <span className="text-gray-50 text-sm">Copyright © 2012-{new Date().getFullYear()} Internationella Engelska Skolan</span>
              </Box>
            </div>
            <div className="hidden sm:block sm:w-2/5 xl:w-1/4 bg-navy-blue-700 xl:bg-navy-blue-500 xl:border-l xl:border-white-trans">
              <Box className="pb-8" />
            </div>
          </Cluster>
        </Stack>
        {/* end footer */}
      </div>
      <div className="hidden xl:block bg-navy-blue-700 xl:bg-navy-blue-500 xl:flex-1" />
    </div>
  </>
);

Footer.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string,
  }).isRequired,
};

export default Footer;
