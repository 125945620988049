import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Link, graphql, StaticQuery } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';
import {
  Popover,
  Transition
} from '@headlessui/react';

import * as Icons from '../Icons/Academics';
import {
  Box,
  Stack,
  Switcher
} from '../../EveryLayout';
import {
  Departments,
  ProgrammeLogos,
  ProgrammeTitles,
} from './';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
};

const SummaryLink = ({ pageData, icon }) => {
  if (pageData?.path?.alias && pageData?.title) {
    const PageIcon = icon;
    return (
      <div className="mb-2">
        <Popover.Button
          as={Link}
          to={pageData.path.alias}
          className="block no-underline transition duration-150 ease-in-out hover:bg-[var(--color-background-50)]"
        >
          <Box className="px-0 xl:px-4">
            <span className="flex items-center">
              <PageIcon className="stroke-2 h-8 w-8 flex-shrink-0" aria-hidden="true" />
              <span className="block ml-3 font-bold underline decoration-2 decoration-[var(--color-link-text)]">{pageData.title}</span>
            </span>
            {pageData.field_subheading?.processed && (
              <span aria-hidden="true" className="prose hidden lg:block mt-3 text-sm leading-relaxed" dangerouslySetInnerHTML={{ __html: pageData.field_subheading.processed }} />
            )}
          </Box>
        </Popover.Button>
      </div>
    );
  }
  return null;
};

const MegaMenuAcademics = ({ menu }) => (
  <StaticQuery
    query={graphql`
      {
        page1: nodePage(drupal_id: {eq: "6908dd1a-87b8-455d-8ab0-98b6cfee7183"}) {
          drupal_internal__nid
          field_subheading {
            processed
          }
          path {
            alias
          }
          title
        }
        page2: nodePage(drupal_id: {eq: "78f1830a-6c9f-4e0c-a399-fa689e2df07f"}) {
          drupal_internal__nid
          field_subheading {
            processed
          }
          path {
            alias
          }
          title
        }
        page3: nodePage(drupal_id: {eq: "976ea450-876d-49a3-abb5-baea0d5cef7b"}) {
          drupal_internal__nid
          field_subheading {
            processed
          }
          path {
            alias
          }
          title
        }
        page4: nodePage(drupal_id: {eq: "9c5e8394-40a3-4acd-b77d-866d83f7ac56"}) {
          path {
            alias
          }
          title
        }
        page5: nodePage(drupal_id: {eq: "6555102b-8ddd-4587-a42c-9dbfcab017c8"}) {
          path {
            alias
          }
          title
        }
        menuDepartments: allMenuLinkContentMenuLinkContent(
          filter: {
            drupal_parent_menu_item: {
              eq: "menu_link_content:86ab47cf-8f2b-4d1f-87b8-853380aa5075"
            },
            enabled: {
              eq: true
            },
          }
          sort: {
            weight: ASC
          }
        ) {
          edges {
            node {
              ...MenuLinkContentFragment
            }
          }
        }
        menuProgrammes: allMenuLinkContentMenuLinkContent(
          filter: {
            drupal_parent_menu_item: {
              eq: "menu_link_content:7c7837ed-0fd8-4127-b7cb-de611580e03e"
            },
            enabled: {
              eq: true
            },
          }
          sort: {
            weight: ASC
          }
        ) {
          edges {
            node {
              ...MenuLinkContentFragment
            }
          }
        }
        programmePages: allNodeProgramme {
          edges {
            node {
              ...NodeProgrammeFragment_no_content
            }
          }
        }
      }
    `}
    render={data => {

      // The sort by weight isn't working; it sorts as a string, even if I type
      // the field as an integer.
      data.menuDepartments.edges.sort((a, b) => Number(a.node.weight) === Number(b.node.weight) ? 0 : Number(a.node.weight) > Number(b.node.weight) || -1);

      return (
        <Popover>
          {({ open }) => (
            <>
              <Popover.Button
                className={classNames(
                  open ? '' : '',
                  'group inline-flex items-center p-2 px-3 bg-navy-blue-500 text-sm hover:bg-navy-blue-700 transition text-white uppercase 2xl:text-lg tracking-wide font-light xl:px-4'
                )}
              >
                <span>Academics</span>
              </Popover.Button>

              <Transition
                as={Fragment}
                enter="transition ease-out duration-200"
                enterFrom="opacity-0 -translate-y-1"
                enterTo="opacity-100 translate-y-0"
                leave="transition ease-in duration-150"
                leaveFrom="opacity-100 translate-y-0"
                leaveTo="opacity-0 -translate-y-1"
              >
                <Popover.Panel className={`absolute z-50 mt-3 transform shadow-lg bg-[var(--color-background-100)] inset-x-4 xl:inset-x-16 2xl:inset-x-44`}>
                  <Switcher className="gap-0 p-px">
                    {/* Primary Left column (larger screens) */}
                    <div className="hidden lg:block min-w-[18rem]">
                      <Box className="bg-[var(--color-background)] h-full pb-8">
                        <div>
                          <div className="lg:grid lg:gap-4 lg:grid-cols-4">
                            {/* Summary links - first two menu items. */}
                            <div className="col-span-2">
                              {data.page1 && (
                                <SummaryLink pageData={data.page1} icon={Icons.TalkIcon} />
                              )}
                              {data.page2 && (
                                <SummaryLink pageData={data.page2} icon={Icons.GraduateIcon} />
                              )}
                            </div>
                            <div className="col-span-2">
                              {/* Departments */}
                              {data?.page4 && data?.menuDepartments?.edges?.length > 1 && (
                                <Box className="px-0 xl:px-4">
                                  <Popover.Button
                                    as={Link}
                                    to={data.page4.path?.alias}
                                    className="block mb-4 ml-2 font-bold"
                                  >
                                    <span>{data.page4.title}</span>
                                  </Popover.Button>
                                  <div>
                                    <Departments edges={data.menuDepartments.edges} />
                                  </div>
                                </Box>
                              )}
                            </div>
                          </div>
                        </div>
                        {/* International Baccalaureate */}
                        {data?.page3 && (
                          <div className="border border-[var(--color-border)]">
                            <Popover.Button
                              as={Link}
                              to={data.page3.path?.alias}
                              className="block no-underline transition duration-150 ease-in-out hover:bg-[var(--color-background-50)]"
                            >
                              <div className="lg:flex items-center h-full px-0 xl:px-4">
                                <Box className="hidden lg:block flex-shrink-0">
                                  <StaticImage
                                    src="../../../images/ib-world-school-logo-1-colour.png"
                                    alt=""
                                    width={125}
                                    placeholder="blurred"
                                    imgClassName="w-24 h-24 object-cover"
                                    aspectRatio="1"
                                    aria-hidden="true"
                                  />
                                </Box>
                                <Box className="prose min-w-0 flex-1 lg:ml-4">
                                  <span to="#" className="block mb-4 font-bold text-[var(--color-link-text)] underline underline-offset-4 decoration-2 decoration-[var(--color-link-text)]">
                                    {data.page3.title}
                                  </span>
                                  {data.page3.field_subheading?.processed && (
                                    <span aria-hidden="true" className="block mb-4 text-sm leading-relaxed" dangerouslySetInnerHTML={{ __html: data.page3.field_subheading.processed }} />
                                  )}
                                </Box>
                              </div>
                            </Popover.Button>
                          </div>
                        )}
                      </Box>
                    </div>
                    {/* Right */}
                    <div className="min-w-[10rem]">
                      {data.programmePages.edges?.length > 1 && (
                        <Box className="h-full pb-8">
                          <Stack>
                            {data.page5 && (
                              <Popover.Button
                                as={Link}
                                to={data.page5.path?.alias}
                                className="block font-bold"
                              >
                                All Programmes
                              </Popover.Button>
                            )}

                            <div aria-hidden={true}>
                              <ProgrammeLogos edges={data.programmePages.edges} />
                            </div>
                            <ProgrammeTitles edges={data.programmePages.edges} />

                          </Stack>
                        </Box>
                      )}
                    </div>
                  </Switcher>
                </Popover.Panel>
              </Transition>
            </>
          )}
        </Popover>
      );
    }}
  />
);

MegaMenuAcademics.propTypes = {
  menu: PropTypes.array.isRequired,
};

export default MegaMenuAcademics;
