import {
  useStaticQuery,
  graphql
} from 'gatsby';

const useSiteMetaData = () => {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          ...SiteMetadataFragment
        }
      }
    `
  )
  return site;
}

export default useSiteMetaData;