// import React from 'react';

const AddToCalendar = () => {
  // return (
  //   <a
  //     href="#"
  //     className="my-4 border border-gray-300 no-underline inline-flex items-center justify-center text-center px-4 py-2 text-base focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2"
  //   >
  //     Add to Calendar
  //   </a>
  // );
  return null;
};

export default AddToCalendar;

// import React from 'react';
// import { atcb_action } from 'add-to-calendar-button';
// import 'add-to-calendar-button/assets/css/atcb.css';
//
// const AddToCalendar = ({ date, day, time, end }) => {
//   return (
//     <form onSubmit={e => {
//       e.preventDefault();
//       atcb_action({
//         name: 'Open House Event - Internationella Engelska Gymnasiet',
//         startDate: day,
//         endDate: day,
//         startTime: time,
//         options: ['Apple', 'Google', 'iCal', 'Microsoft365', 'Outlook.com', 'Yahoo'],
//         timeZone: "Europe/Stockholm",
//         iCalFileName: "Reminder-Event",
//       });
//     }}>
//       <input type="submit" value="save" />
//     </form>
//   );
// };
//
// export default AddToCalendar;

// import React, { useEffect } from "react";
// import { atcb_init } from "add-to-calendar-button";
// import 'add-to-calendar-button/assets/css/atcb.css';
//
// const AddToCalendar = ({ date, day, time }) => {
//
//   useEffect(() => {
//     const init = async () => {
//       atcb_init();
//     };
//     init();
//   }, []);
//
//   const ORG_SCHEMA = JSON.stringify({
//     "name":"Open House Event - Internationella Engelska Gymnasiet",
//     "startDate":day,
//     "endDate":day,
//     "startTime":time,
//     "endTime":time,
//     "label":"Add to Calendar",
//     "options":[
//       "Apple",
//       "Google",
//       "iCal",
//       "Microsoft365",
//       "Outlook.com",
//       "Yahoo"
//     ],
//     "timeZone":"Europe/Stockholm",
//     "iCalFileName":"Reminder-Event"
//   });
//
//   console.log(ORG_SCHEMA);
//   return (
//     <div className="atcb">
//       {ORG_SCHEMA}
//     </div>
//   );
// };
//
// export default AddToCalendar;