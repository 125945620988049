import React from 'react';
import { Link } from 'gatsby';
import { Popover } from '@headlessui/react';

import {
  Box,
  Grid,
  Stack
} from '../../EveryLayout';

import {
  shorten,
  stripTags
} from '../../../helpers';

const ProgrammeTitles = ({ edges }) => {
  return (
    <>
      <Grid className="gap-0 lg:gap-2 2xl:gap-0">
        {edges.map(({ node }, i) => {
          let summary;
          if (node.field_subheading?.processed) {
            let strippedString = stripTags(node.field_subheading.processed);
            summary = shorten(strippedString, 200);
          }
          return (
            <Popover.Button
              as={Link}
              key={`programme-${i}`}
              to={node.path?.alias}
              className="block no-underline transition duration-150 ease-in-out hover:bg-[var(--color-background)]"
            >
              <Box className="p-2 xl:p-4">
                <Stack>
                  <div className="font-bold text-sm underline">{node.title}</div>
                  {summary && (
                    <div aria-hidden={true} className="prose text-sm leading-relaxed mt-2 lg:block">{summary}</div>
                  )}
                </Stack>
              </Box>
            </Popover.Button>
          )
        })}
      </Grid>
      {/*<pre>{ JSON.stringify(edges, {}, 2) }</pre>*/}
    </>
  );
};

export default ProgrammeTitles;
