import React from 'react';
import PropTypes from 'prop-types';

/**
 * Use `break-inside: avoid;` (or tw 'break-inside') on container element to
 * prevent it breaking across columns.
 */

const Columns = (props) => {
  const { children } = props;
  const attrs = {
    className: props.className ? 'columns-3xs gap-4 ' + props.className : 'columns-3xs gap-4',
  };

  const ComponentType = props.as || 'div';

  return (
    <ComponentType {...attrs}>
      {children}
    </ComponentType>
  );
};

Columns.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Columns;
