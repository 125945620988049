import React from 'react';
import PropTypes from 'prop-types';

import GraduateIconSvg from '../../../../static/images/GraduateIcon.svg';
import TalkIconSvg from '../../../../static/images/TalkIcon.svg';

const IconWrapper = ({ children }) => (
  <span className="h-8 w-8">
    {children}
  </span>
);

const GraduateIcon = () => (
  <IconWrapper>
    <img src={GraduateIconSvg} alt="" aria-hidden="true" />
  </IconWrapper>
);

const TalkIcon = () => (
  <IconWrapper>
    <img src={TalkIconSvg} alt="" aria-hidden="true" />
  </IconWrapper>
);

IconWrapper.propTypes = {
  children: PropTypes.node.isRequired,
};

export {
  GraduateIcon,
  TalkIcon,
};
