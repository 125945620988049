import {
  useStaticQuery,
  graphql
} from 'gatsby';

const useNodeArticleRecentData = () => {
  const { allNodeArticle } = useStaticQuery(
    graphql`
      query {
        allNodeArticle(
          sort: [
            {
              sticky: DESC
            },
            {
              created: DESC
            }
          ],
          limit: 3
        ) {
          edges {
            node {
              created(formatString: "MMMM D, YYYY")
              drupal_internal__nid
              relationships {
                field_image_media {
                  id
                  relationships {
                    field_media_image {
                      gatsbyImage(
                        width: 800,
                        aspectRatio: 1.333,
                        formats: [AUTO, WEBP]
                      )
                    }
                  }
                  field_caption
                  field_media_image {
                    alt
                  }
                }
              }
              title
              path {
                alias
              }
            }
          }
        }
      }
    `
  )
  return allNodeArticle;
}

export default useNodeArticleRecentData;