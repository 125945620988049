import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import { Popover } from '@headlessui/react';
import { CalendarIcon } from '@heroicons/react/24/outline';

import {
  Box,
  Cluster,
  Stack,
} from '../EveryLayout';
import {
  ButtonWithIconCrimson,
  LogoInline,
  MenuMain,
  MenuSecondary,
  MobileMenu,
} from '../Page';

const Header = ({ location }) => (
  <>
    <Popover className="relative">
      {/* Mobile menu */}
      <MobileMenu />
      <Stack className="bg-header-grad">
        <Cluster className="pb-4 xl:pb-0 gap-0 bg-navy-blue-600 xl:bg-transparent md:items-stretch md:justify-start">
          <div className="mx-auto box-content sm:relative sm:mx-0 md:w-1/5 lg:w-1/6 2xl:w-1/6">
            {/* Logo */}
            <Box className="relative border-b border-b-navy-blue-600 bg-navy-blue-600 w-full lg:py-6 lg:absolute lg:z-20 lg:top-0 lg:left-0 lg:w-full xl:bg-navy-blue-500 xl:max-h-[20rem] 2xl:py-8">
              <Link
                to="/"
                className="block text-white"
              >
                <LogoInline alt="Internationella Engelska Gymnasiet logo, return to homepage" className="mx-auto h-32 sm:h-24 w-auto lg:h-24 xl:h-28 2xl:h-32" />
              </Link>
            </Box>
          </div>
          {/* Site name */}
          <div className="hidden sm:flex items-center justify-center sm:justify-start w-full sm:w-auto">
            <Box className="xl:pl-8 2xl:py-6">
              <Link
                to="/"
                className="block text-center text-base text-white no-underline transition uppercase tracking-widest hover:text-white md:text-lg 2xl:text-lg"
              >
                Internationella Engelska Gymnasiet
              </Link>
            </Box>
          </div>
          {/* Top bar menu */}
          <MenuSecondary />
        </Cluster>
        <Cluster className="hidden mt-0 gap-0 bg-navy-blue-600 xl:bg-transparent lg:flex lg:justify-start">
          {/* spacer */}
          <div className="order-1 md:w-full md:w-1/5 lg:w-1/6 2xl:w-1/6" />
          {/* Main menu */}
          <MenuMain />
          {/* 'Important dates' button */}
          <Box className="order-3 hidden md:block md:ml-auto 2xl:-mt-4">
            <ButtonWithIconCrimson
              className="shadow-md"
              icon={<CalendarIcon className="stroke-2 h-5 w-5" aria-hidden="true" />}
              to="/important-dates/"
            >
              Important Dates
            </ButtonWithIconCrimson>
          </Box>
        </Cluster>
      </Stack>
    </Popover>
  </>
);

Header.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string,
  }).isRequired,
};

export default Header;
