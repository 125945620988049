import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import { ChevronRightIcon } from '@heroicons/react/24/outline';

import { Cluster } from '../EveryLayout';

import { useMenuData } from '../../hooks';

const Breadcrumb = (props) => {

  const data = useMenuData();

  const { custom = false, activePath } = props;

  const attrs = {
    className: props.className ? 'gap-1 ' + props.className : 'gap-1',
  };

  let trail = [];

  if (!custom && data.mainMenu?.length > 0) {

    data.mainMenu.forEach(({ node }, i) => {
      if (node.url !== activePath) {
        node.childMenuLinkContent.forEach((secondLevelItem, j) => {
          if (secondLevelItem.url !== activePath) {
            secondLevelItem.childMenuLinkContent.forEach((thirdLevelItem, k) => {
              if (thirdLevelItem.url !== activePath) {
                return null;
              }
              else {
                trail = [
                  node,
                  secondLevelItem,
                  thirdLevelItem,
                ];
              }
            })
          }
          else {
            trail = [
              node,
              secondLevelItem,
            ];
          }
        })
      }
      else {
        trail = [
          node,
        ];
      }
    });
  }
  else {
    trail = custom;
  }

  if (trail.length > 1) {
    return (
      <Cluster {...attrs}>
        <>
          {trail && trail.map(({ url, title }, i) => {
            if (i < trail.length - 1) {
              return (
                <React.Fragment key={title}>
                  {i > 0 && (
                    <ChevronRightIcon className="h-3 w-3 flex-shrink-0" aria-hidden="true" />
                  )}
                  {url && (
                    <>
                      <Link
                        key={title}
                        to={`${url}/`}
                        className="decoration-1"
                      >
                        {title}
                      </Link>
                    </>
                  )}
                  {!url && (
                    <>
                      <span>{title}</span>
                    </>
                  )}
                </React.Fragment>
              );
            }
          })}
        </>
      </Cluster>
    );
  }
  else {
    return null;
  }
};

Breadcrumb.propTypes = {
  trail: PropTypes.arrayOf(PropTypes.shape({
    'name': PropTypes.string,
    'path': PropTypes.string,
  })),
};

export default Breadcrumb;
