import React from 'react';
import {
  Disclosure,
  Popover,
  Transition
} from '@headlessui/react';
import { Link } from 'gatsby';
import { CalendarIcon } from '@heroicons/react/24/outline';
import {
  Bars3Icon,
  XMarkIcon,
  ChevronRightIcon
} from '@heroicons/react/24/outline';

import {
  Box,
  Cluster,
  Stack
} from '../EveryLayout';

import { useMenuData } from '../../hooks';

const MobileMenuItem = (props) => {
  const { node, level } = props;
  const baseClasses = `text-white hover:text-white${level < 2 ? ' uppercase tracking-wider' : ''}`;
  return (
    <Cluster>
      {node.url ? (
        <Popover.Button
          as={Link}
          activeClassName="active"
          to={node.url}
          className={`${baseClasses} ml-4 py-3 no-underline ${level > 1 ? '!text-gray-100' : ''}`}
        >
          {node.title}
        </Popover.Button>
      ) : (
        <>
          {node.childMenuLinkContent?.length > 0 && level < 2 && (
            <Disclosure.Button className="w-full">
              <Cluster className="justify-between">
                <span className={`${baseClasses} pl-4 block py-2`}>{node.title}</span>
                <Box>
                  <ChevronRightIcon className="stroke-2 text-white w-4 h-4" />
                </Box>
              </Cluster>
            </Disclosure.Button>
          )}
        </>
      )}
    </Cluster>
  );
};

const MobileMenu = () => {
  const data = useMenuData();
  return (
    <Popover className="_css-mm absolute w-full top-0 right-0 z-10 lg:hidden">
      <Popover.Button className="flex p-2 absolute top-0 right-0 text-gray-50 focus:outline-none focus-visible:ring-1 focus-visible:ring-gray-400">
        <span className="sr-only">Open menu</span>
        <Bars3Icon className="stroke-1 w-10 h-10 lg:w-8 lg:h-8" aria-hidden="true" />
      </Popover.Button>
      <Transition
        enter="transition duration-100 ease-out"
        enterFrom="transform scale-95 opacity-0"
        enterTo="transform scale-100 opacity-100"
        leave="transition duration-75 ease-out"
        leaveFrom="transform scale-100 opacity-100"
        leaveTo="transform scale-95 opacity-0"
        className="absolute inset-0 sm:left-1/2"
      >
        <Popover.Panel className="bg-navy-blue-500">
          <Popover.Button
            type="button"
            className="absolute top-0 right-0 ml-auto flex p-2 md:px-3 md:py-1 focus:outline-none focus-visible:ring-1 focus-visible:ring-gray-400"
          >
            <span className="sr-only">Close</span>
            <XMarkIcon className="stroke-1 text-gray-300 w-8 h-8 md:w-7 md:h-7" aria-hidden="true" />
          </Popover.Button>
          <Box className="px-0">
            <Stack className="mt-10">
             {data.mainMenu?.length > 0 && (
                <nav>
                  <ul className="text-white divide-y divide-white-trans">
                    {data.mainMenu.map(({ node }) => {
                      return (
                        <Disclosure as="li" key={node.drupal_id}>
                          <>
                            <MobileMenuItem node={node} level={1} />
                            {node.childMenuLinkContent.length > 0 && (
                            <Transition
                              enter="transition duration-200 ease-out"
                              enterFrom="transform scale-95 opacity-0"
                              enterTo="transform scale-100 opacity-100"
                              leave="transition duration-75 ease-out"
                              leaveFrom="transform scale-100 opacity-100"
                              leaveTo="transform scale-95 opacity-0"
                            >
                                <Disclosure.Panel as="ul" id={node.drupal_id} className="py-4 bg-navy-blue-600">
                                  <>
                                    {node.childMenuLinkContent.map((nodeB) => {
                                      return (
                                        <li key={nodeB.drupal_id} className="ml-2">
                                          <MobileMenuItem node={nodeB} level={2} />
                                        </li>
                                      )
                                      // return (
                                      //   <Disclosure as="li" key={nodeB.drupal_id} className="ml-2">
                                      //     <MobileMenuItem node={nodeB} className="!text-gray-200" />
                                      //     {nodeB.childMenuLinkContent.length > 0 && (
                                      //     <Transition
                                      //       enter="transition duration-200 ease-out"
                                      //       enterFrom="transform scale-95 opacity-0"
                                      //       enterTo="transform scale-100 opacity-100"
                                      //       leave="transition duration-75 ease-out"
                                      //       leaveFrom="transform scale-100 opacity-100"
                                      //       leaveTo="transform scale-95 opacity-0"
                                      //     >
                                      //         <Disclosure.Panel as="ul" id={nodeB.drupal_id} className="py-4 bg-gray-700">
                                      //           <>
                                      //             {nodeB.childMenuLinkContent.map((nodeC) => {
                                      //               return (
                                      //                 <li key={nodeC.drupal_id} className="ml-2">
                                      //                   <MobileMenuItem node={nodeC} className="!text-gray-200" />
                                      //                 </li>
                                      //               )
                                      //             })}
                                      //           </>
                                      //         </Disclosure.Panel>
                                      //       </Transition>
                                      //     )}
                                      //   </Disclosure>
                                      // )
                                    })}
                                  </>
                                </Disclosure.Panel>
                              </Transition>
                            )}
                          </>
                        </Disclosure>
                      )
                    })}
                  </ul>
                </nav>
              )}
              {data.secondaryMenu?.length > 0 && (
                <nav>
                  <ul className="text-white divide-y divide-white-trans">
                    {data.secondaryMenu.map(({ node }) => (
                      <li key={`${node.drupal_id}`}>
                        <MobileMenuItem node={node} level={1} />
                      </li>
                    ))}
                  </ul>
                </nav>
              )}
              <div className="text-white">
                {/* 'Important dates' button */}
                <Box>
                  <Popover.Button as={Link}
                    to="/important-dates/"
                    className={`transition text-white hover:text-white text-sm rounded-xs uppercase font-semibold tracking-widest no-underline inline-flex items-center justify-center text-center px-6 py-4 text-base focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 shadow-md bg-crimson-500`}
                  >
                    Important Dates
                    <span className="inline-block ml-2 -mr-1 w-5 h-5"><CalendarIcon className="stroke-2 h-5 w-5" aria-hidden="true" /></span>
                  </Popover.Button>
                </Box>
                {/* social */}
              </div>
            </Stack>
          </Box>
        </Popover.Panel>
      </Transition>
    </Popover>
  );
};

export default MobileMenu;
