import {
  useStaticQuery,
  graphql
} from 'gatsby';

const useNodeProgrammeListData = () => {
  const { allNodeProgramme } = useStaticQuery(
    graphql`
      query {
        allNodeProgramme(
          sort: {
            title: ASC
          }
        ) {
          edges {
            node {
              ...NodeProgrammeFragment_minimal
            }
          }
        }
      }
    `
  )
  return allNodeProgramme;
}

export default useNodeProgrammeListData;