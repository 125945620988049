import React from 'react';
import { Link } from 'gatsby';
import { Popover } from '@headlessui/react';

import {
  Menu
} from './';
import {
  MegaMenuAcademics,
  MegaMenuStudentLife
} from './MegaMenu';

import { useMenuData } from '../../hooks';

const MenuMain = () => {
  const data = useMenuData();
  return (
    <>
      {data.mainMenu?.length > 0 && (
        <nav aria-label="Main" className="_box -ml-4 xl:ml-0 order-2 flex gap-1">
          {data.mainMenu.map(({ node }, i) => {
            if (node.childMenuLinkContent.length) {
              // (This needs refactoring!) Not sure if we need this. It's sorting
              // okay right now, but keep it just in case that changes.
              // node.childMenuLinkContent.sort((a, b) => Number(a.weight) === Number(b.weight) ? 0 : Number(a.weight) > Number(b.weight) || -1);

              if (node.drupal_id === 'menu_link_content:25276c2e-6eb6-4c66-a910-aa262c9601aa') {
                return (<MegaMenuAcademics key={`menu-main-${i}`} menu={node.childMenuLinkContent} />);
              }
              else if (node.drupal_id === 'menu_link_content:003db0fd-f5e0-43ad-98f5-18be35a518ee') {
                return (<MegaMenuStudentLife key={`menu-main-${i}`} menu={node.childMenuLinkContent} />);
              }
              return (
                <Menu
                  key={`menu-main-${i}`}
                  menuName={node.title}
                >
                  <ul className="divide-y divide-[var(--color-border)]">
                    {node.childMenuLinkContent.map((child, j) => {
                      return (
                        <li key={`menu-main-${i}-${j}`}>
                          <Popover.Button as={Link}
                            to={child.url}
                            className="block no-underline px-2 py-3 transition duration-150 ease-in-out hover:bg-[var(--color-background-50)]"
                          >
                            <span className="flex items-center">
                              <span className="ml-3 text-base font-semibold">{child.title}</span>
                            </span>
                          </Popover.Button>
                        </li>
                      )
                    })}
                  </ul>
                </Menu>
              )
            }
            return null;
          })}
        </nav>
      )}
    </>
  );
};

export default MenuMain;
