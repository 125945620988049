import React from 'react';
import { Link } from 'gatsby';

import { Cluster } from '../EveryLayout';

import { useMenuSecondaryData } from '../../hooks';

const MenuSecondary = () => {
  const edges = useMenuSecondaryData();
  return (
    <nav aria-label="Secondary" className="p-4 order-3 ml-auto hidden lg:flex">
      <Cluster as="ul" className="items-start">
        {/*<li><Link to={}>På svenska</li>*/}
        {edges.map(({ node }, i) => (
          <li key={`menu-secondary-${i}`}>
            <Link
              to={`${node.url}/`}
              className="no-underline text-gray-200 text-sm hover:underline hover:decoration-1 hover:text-white hover:decoration-white lg:text-base"
            >
              {node.title}
            </Link>
          </li>
        ))}
      </Cluster>
    </nav>
  );
};

export default MenuSecondary;
