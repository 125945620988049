import React from 'react';
import {
  StaticQuery,
  graphql
} from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';

import CoverImage from './CoverImage';

const CoverImageSocSci = () => (
  <StaticQuery
    query={graphql`
      {
       file(name: {eq: "cover_programme-soc_sci"}) {
          childImageSharp {
            gatsbyImageData(width: 1500, aspectRatio: 1.78)
          }
        }
      }
    `}
    render={data => {
      return (
        <CoverImage>
          {/* `borderTop: '1px solid ...'` is a workaround; see https://github.com/gatsbyjs/gatsby/issues/31488#issuecomment-846015187 */}
          <GatsbyImage
            image={data.file.childImageSharp.gatsbyImageData}
            alt=""
            loading="eager"
            placeholder="blurred"
            imgStyle={{ objectFit: 'cover', objectPosition: 'center center', borderTop: '1px solid #0c224f', borderBottom: '1px solid white' }}
          />
        </CoverImage>
      )
    }}
  />
);

export default CoverImageSocSci;
