import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import {
  Popover,
  Transition
} from '@headlessui/react';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
};

const Menu = ({ menuName, inset, children }) => (
  <Popover className="relative">
    {({ open }) => (
      <>
        <Popover.Button
          className={classNames(
            open ? '' : '',
            'group inline-flex items-center p-2 px-3 text-sm bg-navy-blue-500 hover:bg-navy-blue-700 transition text-white uppercase 2xl:text-lg tracking-wide font-light xl:px-4'
          )}
        >
          <span>{menuName}</span>
        </Popover.Button>

        <Transition
          as={Fragment}
          enter="transition ease-out duration-200"
          enterFrom="opacity-0 -translate-y-1"
          enterTo="opacity-100 translate-y-0"
          leave="transition ease-in duration-150"
          leaveFrom="opacity-100 translate-y-0"
          leaveTo="opacity-0 -translate-y-1"
        >
          <Popover.Panel className={`absolute z-50 left-1/4 mt-3 shadow-lg w-screen max-w-sm -translate-x-1/4 transform px-4 sm:px-0 lg:max-w-xs ${inset}`}>
            <div>
              <div className="bg-[var(--color-background)]">
                {children}
              </div>
            </div>
          </Popover.Panel>
        </Transition>
      </>
    )}
  </Popover>
);

Menu.propTypes = {
  menuName: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  inset: PropTypes.string,
};

export default Menu;