import React from 'react';
import PropTypes from 'prop-types';
import { Transition } from '@headlessui/react';
import { XMarkIcon } from '@heroicons/react/24/outline';

import {
  Box,
  Center,
  Cluster
} from '../EveryLayout';

import { useNodeAnnouncementData } from '../../hooks';

const Banner = ({ open, handleSetOpen }) => {
  const { node } = useNodeAnnouncementData();
  if (node) {
    return (
      <Transition
        show={open}
        enter="ease-out duration-300"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="ease-in duration-200"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
        className="relative bg-gray-700"
        role="alert"
      >
        <Center className="max-w-8xl">
          <Cluster className="justify-between flex-nowrap">
            <Box className="pl-3 pr-0 pt-2 pb-3 prose text-gray-200 mr-16">
              <Cluster className="gap-1 flex-nowrap items-start sm:items-center">
                <div className="relative">
                  <div className="w-8 h-8">
                      <span className="-mt-1 block w-8 h-8 text-xl">
                      &#128075;
                    </span>
                  </div>
                </div>
                <span className="banner-html" dangerouslySetInnerHTML={{__html: node.field_formatted_text?.processed }} />
              </Cluster>
            </Box>
            <button
              type="button"
              className="absolute inset-y-0 right-0 ml-auto flex items-start md:items-center p-3 md:px-3 md:py-1 focus:outline-none focus-visible:ring-1 focus-visible:ring-gray-400"
              onClick={() => handleSetOpen(false)}
            >
              <span className="sr-only">Dismiss</span>
              <XMarkIcon className="stroke-2 text-gray-200 w-5 h-5 md:w-7 md:h-7" aria-hidden="true" />
            </button>
          </Cluster>
        </Center>
      </Transition>
    );
  }

  return null;
};

Banner.propTypes = {
  open: PropTypes.bool.isRequired,
  handleSetOpen: PropTypes.func,
};

export default Banner;
