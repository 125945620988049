import React from 'react';
import PropTypes from 'prop-types';

const Section = ({ children, className = '' }) => {
  return (
    <section className={`px-4 pt-8 pb-12 md:pt-6 md:pb-8 xl:pt-12 xl:pb-16 2xl:px-0 2xl:pt-16 2xl:pb-20${ className ? ' ' + className : '' }`}>
      <div className="max-w-8xl mx-auto">
        {children}
      </div>
    </section>
  );
};

Section.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
};

export default Section;
