import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';
import {
  Popover,
  Transition
} from '@headlessui/react';

import * as Icons from '../Icons/StudentLife';
import {
  FadeIn,
  FadeInStagger,
} from '../../Elements/FadeIn';
import {
  Box,
  Center,
  Stack,
  Switcher
} from '../../EveryLayout';
import {
  ButtonBlue,
} from '../';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
};

const MegaMenuStudentLife = ({ menu }) => {

  const iconMap = [
    {
      'icon': Icons.CrestIcon,
      'mid': 'menu_link_content:53348085-606b-4b15-b71a-b863624ef745',
    },
    {
      'icon': Icons.ActivityIcon,
      'mid': 'menu_link_content:51bc1490-791f-4b6f-be68-18a714619162',
    },
    {
      'icon': Icons.BuildingOffice2Icon,
      'mid': 'menu_link_content:3993d0cd-4e2e-4c0d-a022-f425ae65801a',
    },
    {
      'icon': Icons.HeartIcon,
      'mid': 'menu_link_content:0ed2180c-0039-4287-ab20-4d96c294e6cd',
    },
    {
      'icon': Icons.ChatBubbleLeftRightIcon,
      'mid': 'menu_link_content:85e40be9-608e-4df2-a8f8-4d7bc0db1baf',
    },
  ];

  return (
    <Popover>
      {({ open }) => (
        <>
          <Popover.Button
            className={classNames(
              open ? '' : '',
              'group inline-flex items-center p-2 px-3 bg-navy-blue-500 text-sm hover:bg-navy-blue-700 transition text-white uppercase 2xl:text-lg tracking-wide font-light xl:px-4'
            )}
          >
            <span>Student Life</span>
          </Popover.Button>

          <Transition
            as={Fragment}
            enter="transition ease-out duration-200"
            enterFrom="opacity-0 -translate-y-1"
            enterTo="opacity-100 translate-y-0"
            leave="transition ease-in duration-150"
            leaveFrom="opacity-100 translate-y-0"
            leaveTo="opacity-0 -translate-y-1"
          >
            <Popover.Panel className={`absolute z-50 mt-3 transform shadow-lg bg-[var(--color-background-100)] inset-x-4 xl:inset-x-16 2xl:inset-x-44`}>
              <Switcher className="p-px">
                {/* Left column */}
                <div className="min-w-[18rem]">
                  <Box className="bg-[var(--color-background)] h-full pb-8">
                    <Stack>
                      <div className="prose mt-4">
                        <p>
                          <span className="font-bold">We invite you to explore student life at IEGS!</span>
                        </p>
                        <p className="text-sm leading-relaxed">
                          The memories you create at high school, and many of the friends you will make, will be with you for the rest of your life. If you have a global mindset then IEGS is the perfect place to meet like-minded individuals who share your values.
                        </p>
                      </div>
                      <div className="pb-4">
                        <ul className="mt-4">
                          {menu.map((item, i) => {
                            const mapPos = iconMap.findIndex(x => x.mid === item.drupal_id);
                            const getMap = iconMap[mapPos];
                            // Exclude Virtual Tour link
                            if (item.drupal_id !== 'menu_link_content:f173d4c2-4a40-4332-b767-b32e6384380e') {
                              return (
                                <li key={`${item.title}-${i}`}>
                                  <Popover.Button
                                    as={Link}
                                    to={item.url}
                                    className="flex items-center no-underline p-3 transition duration-150 ease-in-out hover:bg-[var(--color-background-50)]"
                                  >
                                    <>
                                      {mapPos > -1 && (
                                        <getMap.icon />
                                      )}
                                      <span className="ml-3 underline font-bold">{item.title}</span>
                                    </>
                                  </Popover.Button>
                                </li>
                              )
                            }
                            return null;
                          })}
                        </ul>
                      </div>
                    </Stack>
                  </Box>
                </div>
                {/* Center column */}
                <div className="hidden lg:block min-w-[10rem]">
                  <div role="region" aria-labelledby="houseLifeRegion" className="_box h-full pb-8">
                    <h3 className="sr-only" id="houseLifeRegion">House Life at IEGS</h3>
                    <Stack>
                      <Popover.Button
                        as={Link}
                        to="/student-life/house-system/"
                        className="block font-bold"
                        aria-hidden="true"
                      >
                        House Life at IEGS
                      </Popover.Button>
                      <StaticImage
                        src="../../../images/3Z5A3979-0.jpg"
                        alt="4 smiling members of a student basketball team pose after a game."
                        width={450}
                        aspectRatio="16/9"
                      />
                      <FadeInStagger faster className="mt-4 grid gap-4 grid-cols-4 p-2">
                        <FadeIn>
                          <StaticImage
                            src="../../../images/ph-shield.png"
                            alt=""
                            width={100}
                            aspectRatio="8/9"
                            placeholder="blurred"
                            imgClassName="object-cover"
                          />
                        </FadeIn>
                        <FadeIn>
                          <StaticImage
                            src="../../../images/kh-shield.png"
                            alt=""
                            width={100}
                            aspectRatio="8/9"
                            placeholder="blurred"
                            imgClassName="object-cover"
                          />
                        </FadeIn>
                        <FadeIn>
                          <StaticImage
                            src="../../../images/rh-shield.png"
                            alt=""
                            width={100}
                            aspectRatio="8/9"
                            placeholder="blurred"
                            imgClassName="object-cover"
                          />
                        </FadeIn>
                        <FadeIn>
                          <StaticImage
                            src="../../../images/ch-shield.png"
                            alt=""
                            width={100}
                            aspectRatio="8/9"
                            placeholder="blurred"
                            imgClassName="object-cover"
                          />
                        </FadeIn>
                      </FadeInStagger>
                      <span className="prose block my-4 text-sm">
                        The house system fosters a strong sense of community and belonging. House life at IEGS is an integral part of the daily running of the school.&nbsp;
                        <span className="text-sm"><Popover.Button as={Link} to="/student-life/house-system/" aria-label="Learn more about the House System">Learn more</Popover.Button></span>
                      </span>
                    </Stack>
                  </div>
                </div>
                {/* Right column */}
                <div className="min-w-[10rem]">
                  <div role="region" aria-labelledby="360TourRegion" className="_box bg-[var(--color-background)] h-full pb-8">
                    <h3 className="sr-only" id="360TourRegion">360 Virtual Tour</h3>
                    <Stack>
                      <a
                        href="https://engelskagymnasiet.view360.se/tour.html"
                        target="_blank"
                        rel="nofollow noreferrer"
                        className="block font-bold"
                        aria-hidden="true"
                      >
                        360 Virtual Tour
                      </a>
                      <StaticImage
                        src="../../../images/360-tour.jpg"
                        alt="A birds-eye view of the school."
                        width={450}
                        aspectRatio="16/9"
                        imgClassName="object-cover"
                      />
                      <span className="prose block my-4 text-sm">
                        We are excited to share a virtual 360 tour of the school. You can have a look into classrooms, the library and many other key features of the school like our aula, art and drama rooms!
                      </span>
                      <Center className="max-w-xs">
                        <ButtonBlue
                          className=""
                          to="https://engelskagymnasiet.view360.se/tour.html"
                          external={true}
                        >
                          Take a Virtual Tour
                        </ButtonBlue>
                      </Center>
                    </Stack>
                  </div>
                </div>
              </Switcher>
            </Popover.Panel>
          </Transition>
        </>
      )}
    </Popover>
  );
};

MegaMenuStudentLife.propTypes = {
  menu: PropTypes.array.isRequired,
};

export default MegaMenuStudentLife;