import {
  useStaticQuery,
  graphql
} from 'gatsby';

const useNodePageOpenHouseNextEventData = () => {
  const { allNodePageOpenHouse } = useStaticQuery(
    graphql`
      query {
        allNodePageOpenHouse(
          limit: 1,
          sort: {
            created: DESC
          }
        ) {
          edges {
            node {
              field_body_nosummary {
                processed
              }
              date: field_event_date
            }
          }
        }
      }
    `
  )
  return allNodePageOpenHouse.edges[0];
}

export default useNodePageOpenHouseNextEventData;
