import {
  useStaticQuery,
  graphql
} from 'gatsby';

const useNodeAnnouncementData = () => {
  const { allNodeAnnouncement } = useStaticQuery(
    graphql`
      query {
        allNodeAnnouncement(
          limit: 1,
          sort: {
            created: DESC
          }
        ) {
          edges {
            node {
              ...NodeAnnouncementFragment
            }
          }
        }
      }
    `
  )
  if (allNodeAnnouncement.edges.length > 0) {
    return allNodeAnnouncement.edges[0];
  }
  return { node: null };
}

export default useNodeAnnouncementData;