import {
  useStaticQuery,
  graphql
} from 'gatsby';

const useMenuDepartmentPagesData = () => {
  const { allMenuLinkContentMenuLinkContent } = useStaticQuery(
    graphql`
      query {
        allMenuLinkContentMenuLinkContent(
          filter: {
            menu_name: {
              eq: "main"
            },
            drupal_parent_menu_item: {
              eq: "menu_link_content:86ab47cf-8f2b-4d1f-87b8-853380aa5075"
            },
            enabled: {
              eq: true
            },
          }
          sort: {
            weight: ASC
          }
        ) {
          edges {
            node {
              ...MenuLinkContentFragment
            }
          }
        }
      }
    `
  );

  // The sort by weight isn't working; it sorts as a string, even if I type
  // the field as an integer.
  allMenuLinkContentMenuLinkContent.edges.sort((a, b) => Number(a.node.weight) === Number(b.node.weight) ? 0 : Number(a.node.weight) > Number(b.node.weight) || -1);

  return allMenuLinkContentMenuLinkContent.edges;
};

export default useMenuDepartmentPagesData;
