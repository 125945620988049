import React from 'react';
import PropTypes from 'prop-types';

import ActivityIconSvg from '../../../../static/images/ActivityIcon.svg';
import BuildingOffice2IconSvg from '../../../../static/images/BuildingOffice2Icon.svg';
import ChatBubbleLeftRightIconSvg from '../../../../static/images/ChatBubbleLeftRightIcon.svg';
import CrestIconSvg from '../../../../static/images/CrestIcon.svg';
import HeartIconSvg from '../../../../static/images/HeartIcon.svg';

const IconWrapper = ({ children }) => {
  return (
    <span className="h-6 w-6">
      {children}
    </span>
  );
};

const ActivityIcon = () => (
  <IconWrapper>
    <img src={ActivityIconSvg} alt="" aria-hidden="true" />
  </IconWrapper>
);

const BuildingOffice2Icon = () => (
  <IconWrapper>
    <img src={BuildingOffice2IconSvg} alt="" aria-hidden="true" />
  </IconWrapper>
);

const ChatBubbleLeftRightIcon = () => (
  <IconWrapper>
     <img src={ChatBubbleLeftRightIconSvg} alt="" aria-hidden="true" />
  </IconWrapper>
);

const CrestIcon = () => (
  <IconWrapper>
    <img src={CrestIconSvg} alt="" aria-hidden="true" />
  </IconWrapper>
);

const HeartIcon = () => (
  <IconWrapper>
      <img src={HeartIconSvg} alt="" aria-hidden="true" />
  </IconWrapper>
);

IconWrapper.propTypes = {
  children: PropTypes.node.isRequired,
};

export {
  ActivityIcon,
  BuildingOffice2Icon,
  ChatBubbleLeftRightIcon,
  CrestIcon,
  HeartIcon,
};
