import React from 'react';
import { Link } from 'gatsby';
import { Popover } from '@headlessui/react';

const Departments = ({ edges }) => (
  <ul>
    {edges.map(({ node }) => {
      return (
        <li
          key={node.drupal_id}
          className="border-b border-[var(--color-border)] last:border-0"
        >
          <Popover.Button
            as={Link}
            to={node.url}
            className="block px-2 py-2 text-sm no-underline hover:bg-[var(--color-background-50)] transition"
          >
            <span className="font-semibold">{node.title}</span>
          </Popover.Button>
        </li>
      )
    })}
  </ul>
);

export default Departments;
