import {
  useStaticQuery,
  graphql
} from 'gatsby';

const useMenuData = () => {
  const { mainMenu, secondaryMenu } = useStaticQuery(
    graphql`
      query {
        mainMenu: allMenuLinkContentMenuLinkContent(
          filter: {
            menu_name: {
              eq: "main"
            },
            drupal_parent_menu_item: {
              eq: ""
            },
            enabled: {
              eq: true
            }
          }
          sort: {
            weight: ASC
          }
        ) {
          edges {
          node {
            ...MenuLinkContentFragment
            childMenuLinkContent {
              ...MenuLinkContentFragment
              childMenuLinkContent {
                ...MenuLinkContentFragment
              }
            }
          }
          }
        }
        secondaryMenu: allMenuLinkContentMenuLinkContent(
          filter: {
            menu_name: {
              eq: "secondary-menu"
            },
            drupal_parent_menu_item: {
              eq: ""
            },
            enabled: {
              eq: true
            }
          }
          sort: {
            weight: ASC
          }
        ) {
          edges {
            node {
              ...MenuLinkContentFragment
            }
          }
        }
      }
    `
  );

  // The sort by weight isn't working; it sorts as a string, even if I type
  // the field as an integer.
  mainMenu.edges.sort((a, b) => Number(a.node.weight) === Number(b.node.weight) ? 0 : Number(a.node.weight) > Number(b.node.weight) || -1);
  secondaryMenu.edges.sort((a, b) => Number(a.node.weight) === Number(b.node.weight) ? 0 : Number(a.node.weight) > Number(b.node.weight) || -1);

  return {
    mainMenu: mainMenu.edges,
    secondaryMenu: secondaryMenu.edges
  };
};

export default useMenuData;