import React from 'react';
import { ExclamationTriangleIcon } from '@heroicons/react/24/outline';

import { Box } from '../EveryLayout';

const Alert = ({ children }) => {
  return (
    <Box role="status" className="border-l-4 border-yellow-400 bg-yellow-50 xl:mb-16">
      <div className="flex">
        <div className="flex-shrink-0">
          <ExclamationTriangleIcon className="h-7 w-7 text-yellow-400" aria-hidden="true" />
        </div>
        <div className="ml-3 text-sm leading-relaxed">
          {children}
        </div>
      </div>
    </Box>
  );
};

export default Alert;
