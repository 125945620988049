import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { SkipNavLink, SkipNavContent } from '@reach/skip-nav';
import { LazyMotion } from 'framer-motion';

import '@reach/skip-nav/styles.css';

import {
  Footer,
  Header
} from './Layout';
import {
  Banner,
  Cover
} from './Page';

import '../styles/fluid.css';
import '../styles/base.css';
import '../styles/extend.css';
import '../styles/animate.css';

const loadFeatures = () =>
  import('../helpers/framer-features.js').then(res => res.default);

const Layout = ({ pageContext, children, location }) => {

  const [openBanner, setOpenBanner] = useState(true);

  if (pageContext.layout?.includes('landing-page')) {
    return (
      <LazyMotion strict features={loadFeatures}>
        {children}
      </LazyMotion>
    );
  }

  return (
    <LazyMotion strict features={loadFeatures}>
      <Banner
        open={openBanner}
        handleSetOpen={setOpenBanner}
      />
      <header className="z-50 relative bg-navy-blue-500">
        <div className="relative z-60">
          <SkipNavLink />
        </div>
        <div className="max-w-[120rem] mx-auto">
          <Header {...{ location }} />
        </div>
      </header>
      <main id="main" className="bg-[var(--color-background)] text-[var(--color-text)] relative">
        <Cover {...{ location }} />
        <SkipNavContent />
        {children}
      </main>
      <footer className="bg-navy-blue-500">
        <Footer {...{ location }} />
      </footer>
    </LazyMotion>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  location: PropTypes.shape({
    href: PropTypes.string,
  }).isRequired,
};

export default Layout;
