/* Helper functions */

export const canUseDOM = !!(
  typeof window !== 'undefined' &&
  window.document &&
  window.document.createElement
);

/**
 * @param {string} dateString - ISO formatted date string
 * @returns {string} - formatted date string
 */
export function formatDateString(dateString) {

  if (isNaN(Date.parse(dateString))) {
    return false;
  }

  const monthNames = ['January', 'February', 'March', 'April', 'May', 'June',
    'July', 'August', 'September', 'October', 'November', 'December'
  ];

  const datetime = dateString.split('T');

  // If no time is provided, return date only.
  if (datetime.length === 1) {
    const [year, month, day] = dateString.split('-');
    return `${monthNames[month - 1]} ${day}, ${year}`;
  }

  const [date, time] = datetime;
  const [year, month, day] = date.split('-');
  const [hour, minute] = time.split(':');

  const monthName = monthNames[month - 1];

  return `${monthName} ${day}, ${year} ${hour}:${minute}`;
}

export function upcomingDate(date) {
  var givenDate = new Date(date);
  var now = new Date();
  if (givenDate < now) {
    return false;
  }
  return true;
};

export function addHours(numOfHours, date = new Date()) {
  date.setTime(date.getTime() + numOfHours * 60 * 60 * 1000);
  return date;
};

export function getFAQItems(field_tags) {
  let items = [];

  // Get all consition tags. This is for matching only.
  const tags = field_tags.map(a => a.name);

  // Loops through each tag node.
  field_tags.forEach((tag) => {

    // Get FAQ nodes for this tag, if they exist.
    if (tag.relationships?.node__faq !== 'undefined') {
      tag.relationships.node__faq.forEach((node) => {

        // Get all tags attached to this FAQ node.
        const nodeTags = node.relationships.field_faq_category.map(a => a.name);

        // Only proceed if FAQ node has ALL condition tags.
        let compare = tags.every(v => nodeTags.includes(v));

        if (compare) {
          // Exclude duplicates.
          const index = items.findIndex(a => a.drupal_internal__nid === node.drupal_internal__nid);
          if (index === -1) {
            node.tag = tag.name;
            // Add FAQ node to return array.
            items.push(node);
          }
        }
      });
    }
  });
  return items.length > 0 ? items : null;
};

export function stripTags(str) {
  return str.replace(/(<([^>]+)>)/gi, "");
};

export function shorten(str, maxLen, separator = ' ') {
  if (typeof str === 'undefined') {
    return '';
  }
  if (str.length <= maxLen) {
    return str;
  }
  return str.substr(0, str.lastIndexOf(separator, maxLen))
    .replace(/(\r\n\t|\n|\r\t)/gm,"")
    +  '\u2026';
};

export function convertToCssClass(string) {
  return string.replace(/[!"#$%&'()*+,./:;<=>?@[\\\]^`{|}~\s]/g, '').toLowerCase();
};
