import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';

const defaultStyles = `
  transition
  rounded-xs
  uppercase
  text-sm
  font-semibold
  tracking-widest
  inline-flex
  items-center
  justify-center
  text-center
  px-4
  xl:px-6
  py-4
  xl:py-5
  focus:outline-none
  focus-visible:ring-2
  focus-visible:ring-offset-2
`;

const colors = {
  'blue': 'text-white hover:text-white bg-logo-blue-700 hover:bg-logo-blue-600',
  'crimson': 'text-white hover:text-white bg-crimson-500 hover:bg-crimson-600',
  'gray': 'text-logo-blue-800 bg-gray-200 hover:bg-gray-100',
};

const Button = ({ to, className = '', external = false, children }) => (
  <>
    {!external ? (
      <Link
        to={to}
        className={`${defaultStyles} no-underline ${className}`}
      >
        {children}
      </Link>
    ) : (
      <a
        href={to}
        className={`${defaultStyles} no-underline ${className}`}
      >
        {children}
      </a>
    )}
  </>
);

const ButtonBlue = ({ to, className = '', external = false, children }) => (
  <Button
    to={to}
    className={`${colors.blue} ${className}`}
    external={external}
  >
    {children}
  </Button>
);

const ButtonCrimson = ({ to, className = '', external = false, children }) => (
  <Button
    to={to}
    className={`${colors.crimson} ${className}`}
    external={external}
  >
    {children}
  </Button>
);

const ButtonWithIcon = ({ icon, to, className = '', alignLeft = false, external = false, children }) => (
  <>
    {!external ? (
      <Link
        to={to}
        className={`${defaultStyles} no-underline whitespace-nowrap ${className}`}
      >
        {alignLeft ? (
          <>
            <span className="inline-block mr-2 -ml-1 w-5 h-5">{icon}</span>
            {children}
          </>
        ) : (
          <>
            {children}
            <span className="inline-block ml-2 -mr-1 w-5 h-5">{icon}</span>
          </>
        )}
      </Link>
    ) : (
      <a
        href={to}
        className={`${defaultStyles} no-underline whitespace-nowrap ${className}`}
      >
        {alignLeft ? (
          <>
            <span className="inline-block mr-2 -ml-1 w-5 h-5">{icon}</span>
            {children}
          </>
        ) : (
          <>
            {children}
            <span className="inline-block ml-2 -mr-1 w-5 h-5">{icon}</span>
          </>
        )}
      </a>
    )}
  </>
);

const ButtonWithIconBlue = ({ icon, to, className = '', alignLeft = false, external = false, children }) => (
  <ButtonWithIcon
    icon={icon}
    to={to}
    className={`${colors.blue} ${className}`}
    alignLeft={alignLeft}
    external={external}
  >
    {children}
  </ButtonWithIcon>
);

const ButtonWithIconCrimson = ({ icon, to, className = '', alignLeft = false, external = false, children }) => (
  <ButtonWithIcon
    icon={icon}
    to={to}
    className={`${colors.crimson} ${className}`}
    alignLeft={alignLeft}
    external={external}
  >
    {children}
  </ButtonWithIcon>
);

const ButtonWithIconGray = ({ icon, to, className = '', alignLeft = false, external = false, children }) => (
  <ButtonWithIcon
    icon={icon}
    to={to}
    className={`${colors.gray} ${className}`}
    alignLeft={alignLeft}
    external={external}
  >
    {children}
  </ButtonWithIcon>
);

const FormButton = ({ type = 'Submit', className = '', children }) => (
  <button
    type={type}
    className={`${defaultStyles} ${className}`}
  >
    {children}
  </button>
);

const FormButtonBlue = ({ type = 'Submit', className = '', children }) => (
  <FormButton
    type={type}
    className={`${colors.blue} ${className}`}
  >
    {children}
  </FormButton>
);

const propTypes = {
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
};

Button.propTypes = {
  to: PropTypes.string.isRequired,
  ...propTypes,
};

ButtonBlue.propTypes = {
  to: PropTypes.string.isRequired,
  ...propTypes,
};

ButtonCrimson.propTypes = {
  to: PropTypes.string.isRequired,
  ...propTypes,
};

ButtonWithIcon.propTypes = {
  icon: PropTypes.node.isRequired,
  ...propTypes,
};

ButtonWithIconBlue.propTypes = {
  icon: PropTypes.node.isRequired,
  ...propTypes,
};

ButtonWithIconCrimson.propTypes = {
  icon: PropTypes.node.isRequired,
  ...propTypes,
};

ButtonWithIconGray.propTypes = {
  icon: PropTypes.node.isRequired,
  ...propTypes,
};

FormButton.propTypes = {
  type: PropTypes.string,
  ...propTypes,
};

FormButtonBlue.propTypes = {
  type: PropTypes.string,
  ...propTypes,
};

export {
  ButtonBlue,
  ButtonCrimson,
  ButtonWithIconBlue,
  ButtonWithIconCrimson,
  ButtonWithIconGray,
  FormButtonBlue,
};
