import React, { forwardRef } from 'react';
import { Link } from 'gatsby';
import {
  Menu,
  Transition
} from '@headlessui/react';
import { ChevronDownIcon } from '@heroicons/react/24/outline';

import { useMenuDepartmentPagesData } from '../../hooks';

const GatsbyLink = forwardRef((props, ref) => {
  let { to, children, ...rest } = props
  return (
    <Link to={to} {...rest}>
      {children}
    </Link>
  )
});

/* Currenty used for Departments pages only. */

const MenuSubmenu = () => {
  const edges = useMenuDepartmentPagesData();
  return (
    <Menu as="div" className="relative inline-block text-left">
      <Menu.Button className="transition whitespace-nowrap rounded-xs uppercase text-sm font-semibold tracking-widest no-underline inline-flex items-center justify-center text-center px-4 py-4 text-base focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 xl:px-6 mx-auto bg-logo-blue-700 text-white hover:bg-logo-blue-600 2xl:bg-logo-blue-600 2xl:hover:bg-logo-blue-700">
        Select a Department
        <span><ChevronDownIcon className="stroke-2 ml-3 -mr-1 h-4 w-4" aria-hidden="true" /></span>
      </Menu.Button>
      <Transition
        as={React.Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className="absolute inset-x-0 z-10 mt-2 w-56 pb-6 origin-top-right bg-[var(--color-background)] shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
          {edges && edges.map(({ node }, i) => (
            <Menu.Item key={node.drupal_id} as={React.Fragment}>
              {({ active }) => (
                <GatsbyLink
                  to={node.url}
                  className="block px-6 pt-6 no-underline"
                >
                  {node.title}
                </GatsbyLink>
              )}
            </Menu.Item>
          ))}
        </Menu.Items>
      </Transition>
    </Menu>
  )
};

export default MenuSubmenu;
