import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import { Popover } from '@headlessui/react';
import { PhoneIcon } from '@heroicons/react/24/outline';

import {
  Box,
  Cluster,
  Stack,
} from '../EveryLayout';
import {
  LogoInline,
  MobileMenu,
} from '../Page';

const HeaderLanding = () => (
  <>
    {/* Mobile menu */}
    <MobileMenu />
    <div className="bg-navy-blue-500">
      <Cluster className="max-w-8xl mx-auto xl:pb-0 gap-0 bg-navy-blue-600 xl:bg-transparent md:items-stretch md:justify-start">
        <div className="min-h-[4rem] flex items-center justify-center sm:justify-start w-full sm:w-auto">
          <Box className="hidden md:block xl:pl-8 2xl:py-6">
            <Link
              to="/"
              className="block text-base text-white no-underline transition uppercase tracking-widest hover:text-white md:text-lg 2xl:text-lg"
            >
              Internationella Engelska Gymnasiet
            </Link>
          </Box>
        </div>
        <nav aria-label="Secondary" className="p-4 order-3 ml-auto hidden lg:flex">
          <Cluster as="ul" className="items-center">
            <li>
              <Link
                to="/contact/"
                className="inline-flex no-underline text-gray-200 text-sm hover:underline hover:decoration-1 hover:text-white hover:decoration-white lg:text-base"
              >
                <PhoneIcon className="w-5 h-5 mr-2" />
                Contact Us
              </Link>
            </li>
          </Cluster>
        </nav>
      </Cluster>
    </div>
  </>
);


export default HeaderLanding;
