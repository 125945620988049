import React from 'react';

import Logo from '../../images/iegs-logo.svg';

/* eslint-disable jsx-a11y/alt-text */
const LogoInline = (props) => (
  <img src={Logo} width="144" height="144" {...props} />
);
/* eslint-enable jsx-a11y/alt-text */

export default LogoInline;
