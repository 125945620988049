import React from 'react';
import { Link } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import { Popover } from '@headlessui/react';

import {
  FadeIn,
  FadeInStagger,
} from '../../Elements/FadeIn';

const ProgrammeLogos = ({ edges }) => {
  return (
    <>
      <FadeInStagger faster className="_cluster gap-1 flex-nowrap">
        {edges.map(({ node }, i) => (
          <Popover.Button
            as={Link}
            key={`programme-${i}`}
            to={node.path?.alias}
            className="block"
            tabIndex="-1"
          >
            <>
              <span className="sr-only">{node.title}</span>
              <FadeIn className="_cluster flex-nowrap">
                {/* Best to mark image as decorative - alt="" - when used alongside text in a link. */}
                <GatsbyImage
                  image={node.relationships?.field_programme_tile?.relationships?.field_media_image?.gatsbyImage}
                  alt=""
                />
              </FadeIn>
            </>
          </Popover.Button>
        ))}
      </FadeInStagger>
    </>
  );
};

export default ProgrammeLogos;
