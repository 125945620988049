import {
  useStaticQuery,
  graphql
} from 'gatsby';

const useNodeStoryData = () => {
  const { allNodeStory } = useStaticQuery(
    graphql`
      query {
        allNodeStory(
          sort: {
            created: DESC
          }
        ) {
          edges {
            node {
              ...NodeStoryFragment_no_content
            }
          }
        }
      }
    `
  )
  return allNodeStory;
}

export default useNodeStoryData;