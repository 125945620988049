import React from 'react';
import {
  StaticQuery,
  graphql
} from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';

/* Unlike the other components in this folder, it doesn't use the CoverImage
   wrapper but gets pulled directly into ../Cover.js. */

const CoverImageHome = () => (
  <StaticQuery
    query={graphql`
      {
       file(name: {eq: "cover-3Z5A5353"}) {
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH, formats: [AUTO,WEBP,AVIF], aspectRatio: 1.78)
          }
        }
      }
    `}
    render={data => {
      return (
        <>
          {/* `borderTop: '1px solid ...'` is a workaround; see https://github.com/gatsbyjs/gatsby/issues/31488#issuecomment-846015187 */}
          <GatsbyImage
            image={data.file.childImageSharp.gatsbyImageData}
            alt=""
            loading="eager"
            placeholder="blurred"
            imgStyle={{ objectFit: 'cover', objectPosition: 'top center', borderTop: '1px solid #0c224f', borderBottom: '1px solid white' }}
          />
        </>
      )
    }}
  />
);

export default CoverImageHome;
