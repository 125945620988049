import {
  useStaticQuery,
  graphql
} from 'gatsby';

const useNodeFileOpenHouseData = () => {
  const data = useStaticQuery(
    graphql`
      query {
       image: file(name: {eq: "Open-House-1"}) {
          childImageSharp {
            gatsbyImageData(quality: 80, width: 700, aspectRatio: 1.33)
          }
        }
        eventInfo: allNodePageOpenHouse(
          limit: 1,
          sort: {
            created: DESC
          }
        ) {
          edges {
            node {
              date: field_event_date
              field_body_nosummary {
                processed
              }
              path {
                alias
              }
            }
          }
        }
      }
    `
  );
  return data;
};

export default useNodeFileOpenHouseData;

