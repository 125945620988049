import React from 'react';

import { Cluster } from '../../EveryLayout';

const CoverImage = ({ children }) => {
  return (
    <>
      <div className="relative lg:overflow-hidden">
        <div className="w-full h-full">
          <div className="!aspect-w-4 !aspect-h-3 sm:!aspect-w-16 sm:!aspect-h-9 xl:!aspect-h-8 2xl:!aspect-h-7">
            {children}
          </div>
          <Cluster className="hidden md:flex items-stretch justify-start absolute top-0 z-10 w-full h-full">
            <div className="box-content sm:relative sm:mx-0 md:w-1/5 2xl:w-1/6" />
            <div className="flex items-center justify-center sm:justify-start w-full sm:w-auto sm:mr-10" />
          </Cluster>
        </div>
      </div>
    </>
  );
};

export default CoverImage;
